<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-4 col-12">Incomplete Voters</div>
        <div class="
      col-lg-8 col-12 text-end
      d-flex
      justify-content-end
    ">
            <div class="input-group input-group-sm custom-form-group mb-0 me-2"
                v-bind:style="[searchnamebtnactive ? { width: '328px' } : { width: '275px' }]">
                <input type="text" v-model="searchfullname" class="form-control text-capitalize"
                    placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
                    autocomplete="off" maxlength="30" v-on:keyup.enter="searchFnameVoter(searchfullname)" @keypress="isLetterWithSpace($event)" :disabled="hidesearchname"
                    style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
                    @click="searchFnameVoter(searchfullname)" :style="{ width: '50px' }">
                    <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchnameloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameVoterStop()"
                    :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times" aria-hidden="true"></i>
                </button>
            </div>
            <div class="input-group input-group-sm custom-form-group mb-0"
                v-bind:style="[searchmobbtnactive ? { width: '275px' } : { width: '220px' }]">
                <input type="text" v-model="searchmobile" class="form-control" id="searchmobInput"
                    placeholder="Search By Mobile No." :disabled="hidesearchmob" aria-label="Search"
                    aria-describedby="basic-addon1" autocomplete="off" maxlength="10" v-on:keyup.enter="searchMobVoter(searchmobile)" @paste="onPasteMobile" @keypress="mobileSearchFormat"
                    style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchmobile.length <= 3"
                    @click="searchMobVoter(searchmobile)" :style="{ width: '50px' }">
                    <span v-if="!searchmobloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchmobloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchMobVoterStop()"
                    :style="{ width: '50px' }" v-show="searchmobbtnactive"><i class="pi pi-times" aria-hidden="true"></i>
                </button>
            </div>
            <!-- <button v-if="selectedvoter != null && selectedvoter != ''" type="button"
                class="header-btn-outer btn btn-primary" @click="bulkCampaignModalOpen()">
                Bulk Campaign
            </button> -->
            <button type="button" @click="voterFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="company-section-outer">
        <div class="layout-content-section custom-bg-datatable-transparent">
            <div class="custom-ultima-datatable custom-voter-datable custom-bg-datatable-transparent custom-without-checkbox-datatable" style="height: calc(100vh - 141px)">
                <DataTable :value="inCompleteVoterList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                    :paginator="true" :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :lazy="true" dataKey="ak1" v-model:selection="selectedvoter" :loading="loading">
                    <!-- <template #paginatorstart>
                      <div>
                          <span class="voter-only-circle-pagination me-1"></span>
                          <span class="voter-circle-pagination-text">Voter</span>
                          <span class="voter-user-circle-pagination me-1"></span>
                          <span class="voter-circle-pagination-text">User</span>
                          <span class="voter-karyakartas-circle-pagination me-1"></span>
                          <span class="voter-circle-pagination-text">Karyakartas</span>
                      </div>
                  </template> -->
                    <!-- <template #paginatorend></template> -->
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column header="Select All" headerStyle="width: 99%" bodyStyle="width: 99%">
                        <template #body="{ data }">
                            <div class="company-detail-box-outer mb-3">
                                <div class="row g-2">
                                    <div class="
                            col-lg-2 col-md-2 col-12
                            voter-profile-col
                            d-flex
                            align-items-center
                          ">
                                        <div class="customer-label-group">
                                            <div class="d-flex align-items-center">
                                                <div :class="data.ak29 == 3
                                                    ? 'border-voter-user' : data.ak29 == 2 ? 'border-voter-karyakartas'
                                                        : 'border-voter-only'
                                                    ">
                                                    <img v-if="(data.ak90 == null || data.ak90 == '') && (data.ak33 == null || data.ak33 == '')"
                                                        src="/assets/images/profile.svg" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                  " alt="" style="width: 72px; height: 72px;" />
                                                    <img v-if="data.ak90 != null && data.ak90 != ''"
                                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + data.ak90"
                                                        class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                                    <img v-if="(data.ak33 != null && data.ak33 != '') && (data.ak90 == null || data.ak90 == 'NULL' || data.ak90 == '')"
                                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + data.ak33"
                                                        class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                                    <img v-if="data.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg"
                                                        alt="" title="Voter" width="16" />
                                                    <img v-if="data.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg"
                                                        alt="" title="Karyakartas" width="16" />
                                                    <img v-if="data.ak29 == 3"
                                                        src="/assets/images/icon/customers-tab-icon.svg" alt="" title="User"
                                                        width="16" />
                                                </div>
                                            </div>
                                            <div class="text-center from-label-value mt-1"
                                                v-if="(data.ak120 != null && data.ak120 != '')">{{ data.ak120 }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2 d-inline-block">
                                                    <label class="form-label">Voter Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        <span style="width: 233px;" class="text-ellipsis"
                                                        :title="data.ak5 ? firstCapitalize(data.ak5) : ''">{{ data.ak5 ? data.ak5 : "N/A" }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label"><span
                                                            style="width: 50px; display: inline-block;">Gender</span><span
                                                            class="mx-1 vertical-pipe-color">|</span><span>Blood Group</span></label>
                                                    <div class="from-label-value text-capitalize">
                                                        <span style="width: 50px; display: inline-block;">{{
                                                            data.ak22
                                                            ? data.ak22 == 1
                                                                ? "Male"
                                                                : data.ak22 == 2
                                                                    ? "Female"
                                                                    : "Others"
                                                            : "N/A"
                                                        }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak21 ?
    data.ak21 : "N/A" }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Vidhan Sabha</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.ak61 ? data.ak61 : "N/A" }}<span class="ms-1"
                                                            v-if="data.ak140">({{ data.ak140 }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label"><span
                                                            style="width: 87px; display: inline-block;">Date of
                                                            Birth</span><span class="mx-1 vertical-pipe-color">|</span><span>Age</span></label>
                                                    <div class="from-label-value">
                                                        <span style="width: 87px; display: inline-block;">{{
                                                            format_date(data.ak18)
                                                        }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak19 ?
    data.ak19 : "N/A" }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label"><span
                                                            style="width: 87px; display: inline-block;">Voter Id</span><span class="mx-1 vertical-pipe-color">|</span><span>Part
                                                            No.</span></label>
                                                    <div class="from-label-value text-capitalize">
                                                        <span style="width: 87px; display: inline-block;">{{ data.ak35 ?
                                                            data.ak35 : "N/A"
                                                        }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak85 ?
    data.ak85 : "N/A" }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Lok Sabha</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ data.ak63 ? data.ak63 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2 float-start">
                                                    <label class="form-label"><span
                                                            style="width: 127px; display: inline-block;">Profession</span><span
                                                            class="mx-1 vertical-pipe-color">|</span><span
                                                            style="width: 95px; display: inline-block;">Designation</span></label>
                                                    <div class="from-label-value text-capitalize">
                                                        <span style="width: 127px;" class="text-ellipsis"
                                                        :title="data.ak67 ? firstCapitalize(data.ak67) : ''">{{ data.ak67 ?
                                                                data.ak67 : "N/A"
                                                            }}</span><span class="mx-1 float-start vertical-pipe-color">|</span><span
                                                            style="width: 95px;" class="text-ellipsis"
                                                            :title="data.ak84 ? firstCapitalize(data.ak84) : ''">{{ data.ak84 ? data.ak84 : "N/A" }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label"><span
                                                            style="width: 127px; display: inline-block;">Area</span><span
                                                            class="mx-1 vertical-pipe-color">|</span><span>Pincode</span></label>
                                                    <div class="from-label-value text-capitalize">
                                                        <span style="width: 127px;" class="text-ellipsis">
                                                            <span v-if="data.ak44 != null && data.ak44 != '' && data.ak53 == '' && data.ak53 == null"
                                                            :title="data.ak44 ? firstCapitalize(data.ak44) : ''">{{ data.ak44
                                                                }}</span>
                                                            <span v-else-if="data.ak53 != ''" :title="data.ak53 ? firstCapitalize(data.ak53) : 'N/A'">{{ data.ak53
                                                            }}</span>
                                                            <span v-else>N/A</span>
                                                            <span v-if="data.ak53 == null || data.ak53 == '' && data.ak44 == null">N/A</span>
                                                        </span><span class="mx-1 vertical-pipe-color">|</span><span>
                                                            <span v-if="data.ak58 != null && data.ak58 != '' && data.ak97 == null && data.ak97 == ''">{{ data.ak58 }}</span>
                                                            <span v-else-if="data.ak97 != null && data.ak97 != '' && data.ak58 == null && data.ak58 == ''">{{ data.ak97
                                                            }}</span>
                                                            <span v-else>N/A</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Reg. Date & Time</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ format_timestamp(data.z501) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2 d-flex
                                justify-content-between">
                                                    <div>
                                                        <label class="form-label">Mobile</label>
                                                        <div class="from-label-value"
                                                            v-if="this.allowshowmobilevoterfk == 0">
                                                            +91-{{ data.ak24 ? maskedNumber(data.ak24) : "N/A" }}
                                                        </div>
                                                        <div class="from-label-value"
                                                            v-if="this.allowshowmobilevoterfk == 1">
                                                            +91-{{ data.ak24 ? data.ak24 : "N/A" }}
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-end">
                                                        <!-- Signal oprator condition start -->
                                                        <span v-if="this.virtualNumberCount != 0 && this.allowtouseroutgoingcall != 0">
                                                            <button v-if="this.virtualNumberCount == 1"
                                                                class="btn custom-outline-call-btn me-2" title="Call"
                                                                :disabled="callstatus == 1"
                                                                @click="CallTrigerOperatorNumber(data.ak1, data.ak24, this.virtualNumberList[0]['value'])">
                                                                <i class="pi pi-phone call-btn-color"></i></button>
                                                            <!-- Signal oprator condition end -->
                                                            <!-- more oprator condition start -->
                                                            <button class="btn custom-outline-call-btn me-2" title="Call"
                                                                @click="toggleOperatorCallList(data.ak1, $event)"
                                                                :disabled="callstatus == 1"
                                                                v-if="this.virtualNumberCount != 1">
                                                                <i class="pi pi-phone call-btn-color"></i></button>
                                                            <OverlayPanel :ref="`${data.ak1}`" appendTo="body"
                                                                id="overlay_panel" class="custom-overlay-call-btn">
                                                                <div class="overlay-reg-label"
                                                                    v-for="opt  in virtualNumberList" :key="opt">
                                                                    <div>
                                                                        <button class="btn call-trigger-list-btn"
                                                                            @click="CallTrigerOperatorNumber(data.ak1, data.ak24, opt.value, $event)">
                                                                            <i class="pi pi-phone overlay-icon me-2"
                                                                                style="font-size: 11px;"></i> {{
                                                                                    opt.label }}
                                                                        </button>
                                                                    </div>
                                                                    <Divider class="my-1" />
                                                                </div>
                                                            </OverlayPanel>
                                                        </span>
                                                        <!-- more oprator condition end -->
                                                        <!-- <button class="btn custom-outline-whatsapp-btn"
                                                            title="WhatsApp History"
                                                            @click="whatsappHistoryModalOpen(data)">
                                                            <i class="pi pi-whatsapp whatsapp-color"></i>
                                                        </button> -->
                                                        <button class="btn custom-outline-whatsapp-btn ms-2" title="Webbot History"
                                      @click="webbotHistoryModalOpen(data)">
                                      <i class="pi pi-comments whatsapp-color"></i>
                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Email</label>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailvoterfk == 0 && (data.ak23 != '') && (data.ak23 != null)">
                                                        {{ maskedEmail(data.ak23) }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="this.allowshowemailvoterfk == 1 && (data.ak23 != '' && data.ak23 != null)">
                                                        {{ data.ak23 }}
                                                    </div>
                                                    <div class="from-label-value"
                                                        v-if="data.ak23 == '' || data.ak23 == null">
                                                        N/A
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <div class="
                                  customer-label-group
                                  mt-2 mb-0
                                  d-flex
                                  justify-content-end
                                ">
                                                    <button type="button" title="View Details"
                                                        class="btn custom-outline-view-btn me-2" @click="
                                                            voterdetailModalOpen(data)
                                                            ">
                                                        <i class="pi pi-eye call-btn-color"></i> View
                                                    </button>
                                                    <button type="button" title="Edit Voter Details"
                                                        class="btn custom-outline-view-btn"
                                                        @click="voterDetailEditModalOpen(data)"
                                                        v-if="this.alloweditvoterfk == 1">
                                                        <i class="pi pi-pencil call-btn-color"></i> Edit
                                                    </button>
                                                    <button type="button" title="Delete Voter" class="btn custom-outline-delete-btn ms-2"
                                    @click="confirmDeleteVoterModalOpen(data)" v-if="this.allowdeletevoterfk == 1">
                                    <i class="pi pi-trash delete-btn-color"></i> <span
                                      class="delete-btn-color">Delete</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="voterfiltermodalsflag">
        <div class="
      modal-md modal-dialog-scrollable modal-fullscreen
      ms-auto
      custom-modal-outer
    ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="voterFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">System Voter No.</label>
                                        <input type="text" v-model="ak120" class="form-control"
                                            placeholder="Enter Part Number" minlength="2" maxlength="7"
                                            @keypress="onlyNumber" @paste="onPasteMobile" autocomplete="off" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Gender</label>
                                        <Multiselect v-model="ak22" :options="genderList" mode="tags" :createTag="false"
                                            label="label" class="multiselect-custom text-capitalize" placeholder="Select"
                                            :closeOnSelect="true" :searchable="false" :object="false" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-5 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Age</label>
                                        <input type="text" v-model="agerange" class="form-control" placeholder="Enter Age"
                                            minlength="1" maxlength="2" @paste="onPasteMobile" @keypress="onlysingleagechange"
                                            autocomplete="off" />
                                        <span class="custom-error" v-if="agerangeeerr">
                                            {{ agerangeeerr }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-12">
                                    <div class="customer-label-group text-center" style="margin-top: 35px;">
                                        <label class="from-label-value">-- OR --</label>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-5 col-12">
                                    <div class="row custom-form-group">
                                        <div class="col-lg-5 col-md-5 col-12">
                                            <div class="custom-form-group mb-0">
                                                <label class="form-label">Age Range</label>
                                                <input type="text" v-model="agerangefrom" class="form-control"
                                                    placeholder="From" minlength="1" maxlength="2"
                                                    @keypress="agerangechange" autocomplete="off" />
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-12">
                                            <div class="custom-form-group text-center mb-0" style="margin-top: 35px;">-
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-5 col-12">
                                            <div class="custom-form-group mb-0" style="margin-top: 5px;">
                                                <label class="form-label"></label>
                                                <input type="text" v-model="agerangeto" :disabled="agerangefrom == ''"
                                                    class="form-control" placeholder="To" minlength="1" maxlength="2"
                                                    @keypress="agerangechange" autocomplete="off" />
                                            </div>
                                        </div>
                                        <span class="custom-error" v-if="agerangefromeerr">
                                            {{ agerangefromeerr }}
                                        </span>
                                        <span class="custom-error" v-if="agerangetoeerr">
                                            {{ agerangetoeerr }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">District</label>
                    <Multiselect v-model="ak94" :options="filterdistrictList" mode="tags" :createTag="false"
                      :searchable="true" label="label" @change="getcityListByDIstrict($event)" placeholder="Select" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" :object="false" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">City</label>
                    <Multiselect v-show="!cityfilterloaderflag" v-model="ak45" :options="filtercityList" mode="tags" :createTag="false"
                      :searchable="true" @change="getareaListByCity($event)" label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" :object="false" />
                      <Multiselect v-show="cityfilterloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Area</label>
                    <Multiselect v-show="!areafilterloaderflag" v-model="ak44" :options="filterareaList" mode="tags" :createTag="false"
                      :searchable="true" label="label" @select=getpincodeListByArea(ak44) placeholder="Select" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" :object="false" />
                      <Multiselect v-show="areafilterloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pincode</label>
                    <Multiselect v-show="!pincodefilterloaderflag" v-model="pincodevalue" :options="filterpincodeList" mode="tags" :createTag="false"
                      :searchable="true" label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" :object="true" minlength="6" maxlength="6" />
                      <Multiselect v-show="pincodefilterloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                  </div>
                </div>
              </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Voter Id</label>
                                        <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                                            placeholder="Enter Voter Id" maxlength="10" autocomplete="off" />
                                        <span class="custom-error" v-if="voteriderr">
                                            {{ voteriderr }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group mb-0">
                                        <label class="form-label">Aadhar No.</label>
                                        <input type="text" v-model="aadharcardnumber" class="form-control text-uppercase"
                                            id="formaadharcardinput" placeholder="Enter Aadhar No." autocomplete="off"
                                            minlength="12" maxlength="12" @input="checkAadharNumber()"
                                            @keypress="onlyNumber" @paste="onPasteMobile" />
                                        <span class="custom-error" v-if="aadharerr">
                                            {{ aadharerr }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Profession</label>
                                        <Multiselect v-model="profession" :options="voterprofessionList" mode="tags"
                                            :createTag="false" label="label"
                                            class="multiselect-custom form-custom-select text-capitalize"
                                            placeholder="Select" :closeOnSelect="true" :searchable="true" :object="false" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Part Number</label>
                                        <Multiselect v-model="ak85" :options="reassignpartnoList" mode="tags"
                                            :createTag="false" label="label"
                                            class="multiselect-custom form-custom-select text-capitalize"
                                            placeholder="Select" :closeOnSelect="true" :searchable="true" :object="false" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Blood Group</label>
                                        <Multiselect v-model="ak20" :options="bloodgroupList" mode="tags" :createTag="false"
                                            :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom text-capitalize" :canClear="true"
                                            :closeOnSelect="true" :object="false" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Tags</label>
                                        <Multiselect v-model="ak121" :options="tagfiltergroupList" mode="tags" :createTag="false"
                                            label="label" class="multiselect-custom form-custom-select text-capitalize"
                                            placeholder="Select" :closeOnSelect="true" :searchable="true" :object="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="(profession == '' && ak20 == '' && ak22 == '' && agerange == '' && agerangefrom == '' && agerangeto == '' && ak94 == '' && ak45 == '' && ak44 == '' && pincodevalue == '' && votercardnumber == '' && aadharcardnumber == '' && ak85 == '' && ak120 == '' && ak121 == '') || (this.agerangeeerr != '' || this.agerangefromeerr != '' || this.agerangetoeerr != '' || this.pincodeerr != '' || this.voteriderr != '' || this.aadharerr != '')"
                                @click="
                                    btnFilterSearch(
                                        profession,
                                        ak20,
                                        ak22,
                                        agerange,
                                        agerangefrom,
                                        agerangeto,
                                        ak94,
                                        ak45,
                                        ak44,
                                        pincodevalue,
                                        votercardnumber,
                                        aadharcardnumber,
                                        ak85,
                                        ak120,
                                        ak121
                                    )
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
    <!-- next action modal start here -->
    <div class="modal-mask" v-if="viewvoterdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Voter Details</h5>
                    <button type="button" class="btn-close" @click="voterDetailModalClose()"></button>
                </div>
                <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="voter-idcard-tab" data-bs-toggle="tab"
                            data-bs-target="#votercarddetails" type="button" role="tab" aria-controls="appointment-history"
                            aria-selected="true" @click="getvoterdetails()">
                            Voter Id
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="voter-aadharcard-tab" data-bs-toggle="tab"
                            data-bs-target="#voteraadhardetails" type="button" role="tab" aria-controls="vehicle-details"
                            aria-selected="false" @click="getaadhardetails()">
                            Aadhar
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="voter-pancard-tab" data-bs-toggle="tab"
                            data-bs-target="#voterpandetails" type="button" role="tab" aria-controls="customer-details"
                            aria-selected="false" @click="getpandetails()">
                            PAN
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="voter-driving-tab" data-bs-toggle="tab"
                            data-bs-target="#voterdrivingdetails" type="button" role="tab" aria-controls="contact-history"
                            aria-selected="false" @click="getlicensedetails">
                            Driving Licence
                        </button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button disabled class="nav-link" id="voter-schemes-tab" data-bs-toggle="tab"
                            data-bs-target="#voterschemesdetails" type="button" role="tab" aria-controls="customer-details"
                            aria-selected="false" @click="selectedtab((e = 1))">
                            Schemes
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button disabled class="nav-link" id="voter-history-tab" data-bs-toggle="tab"
                            data-bs-target="#voterhistorydetails" type="button" role="tab" aria-controls="customer-details"
                            aria-selected="false" @click="selectedtab((e = 2))">
                            History
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button disabled class="nav-link" id="voter-hierarchy-tab" data-bs-toggle="tab"
                            data-bs-target="#voterhierachydetails" type="button" role="tab" aria-controls="contact-history"
                            aria-selected="false" @click="selectedtab((e = 5))">
                            Family Hierarchy
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button disabled class="nav-link" id="voter-engagements-tab" data-bs-toggle="tab"
                            data-bs-target="#voterengagementsdetails" type="button" role="tab"
                            aria-controls="contact-history" aria-selected="false" @click="selectedtab((e = 6))">
                            Engagements
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button disabled class="nav-link" id="voter-influencers-tab" data-bs-toggle="tab"
                            data-bs-target="#voterinfluencersdetails" type="button" role="tab"
                            aria-controls="contact-history" aria-selected="false" @click="selectedtab((e = 7))">
                            Influencers
                        </button>
                    </li> -->
                </ul>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="votercarddetails" role="tabpanel"
                            aria-labelledby="voter-idcard-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloadervotermodal">
                                    <div v-if="voterinputdisplayflag">
                                        <div class="row" v-if="voterarraylength < 3">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formVoterNameInput" class="form-label">Voter Id
                                                                <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="votercardnumber"
                                                                class="form-control text-uppercase" id="formVoterNameInput"
                                                                placeholder="Enter Voter Id" autocomplete="off"
                                                                maxlength="10" />
                                                            <span class="custom-error" v-if="voteriderr">
                                                                {{ voteriderr }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="
                            col-lg-4 col-md-4 col-12
                          ">
                                                        <div class="custom-form-group" style="margin-top: 26px;">
                                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                                @click="fetchvoteriddetails()" :disabled="errStatus || votercardnumber.length == 0 || showloader
                                                                    " :style="{ width: '104px' }">
                                                                <span v-if="!showloader">Get Details</span>
                                                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3" v-if="alreadyExitVoterMessage">
                                                    <div class="col-12">
                                                        <div class="custom-img-error">{{ this.alreadyExitVoterMessage }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mt-0 mb-3" v-if="voterdivdisplayflag && voterarraylength < 3" />
                                    <!-- open modal to display if voter ID was exits are Single Only start here -->
                                    <div v-if="voterdivdisplayflag && voterarraylength == 1">
                                        <div class="voter-detail-card mb-3" v-for="votergovdata in voterTabDetails"
                                            :key="votergovdata">
                                            <div class="row mb-2">
                                                <div class="text-end">
                                                    <span v-if="votergovdata.ab26 == 1"
                                                        class="status-primary">Primary</span>
                                                    <span v-else-if="votergovdata.ab26 == 2"
                                                        class="status-secondary">Secondary</span>
                                                    <span v-else class="status-other">Other</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Voter Number</label>
                                                        <div class="from-label-value text-uppercase">
                                                            {{
                                                                votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Age</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Gender</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ak22
                                                                ? votergovdata.ak22 == 1
                                                                    ? "Male"
                                                                    : votergovdata.ak22 == 2
                                                                        ? "Female"
                                                                        : "Others"
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Polling Station</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Area</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ak44
                                                                ? votergovdata.ak44
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">State</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ak48
                                                                ? votergovdata.ak48
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ab22
                                                                ? votergovdata.ab22
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovdata.ab23
                                                                ? votergovdata.ab23
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Parliamentary Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovdata.ab24
                                                                ? votergovdata.ab24
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- open modal to display if voter ID was exits are Single Only end here -->
                                    <!-- open modal to display if voter ID was exits are Multiple start here -->
                                    <div class="d-flex align-items-start" v-if="voterarraylength > 1">
                                        <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab"
                                            role="tablist">
                                            <li class="nav-item" role="presentation"
                                                v-for="(votergovdata, index) in voterTabDetails" :key="index">
                                                <button class="nav-link" :class="votergovdata.ab26 == 1 ? 'active' : ''"
                                                    :id="'headingNumberSet' + index" data-bs-toggle="tab"
                                                    :data-bs-target="'#collapseVirtualNumber' + index" type="button"
                                                    role="tab" aria-selected="true">
                                                    <span class="d-flex justify-content-between align-items-center"><span>{{
                                                        votergovdata.ak35
                                                    }}</span>
                                                        <span class="user-badge status-active"
                                                            v-if="votergovdata.ab26 == 1"><i
                                                                class="pi pi-check-circle text-success me-1"
                                                                :style="{ fontSize: '10px' }"></i>Primary</span></span>
                                                </button>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane fade" :class="votergovdata.ab26 == 1 ? 'show active' : ''"
                                                :aria-labelledby="'headingNumberSet' + index" role="tabpanel"
                                                :id="'collapseVirtualNumber' + index"
                                                v-for="(votergovdata, index) in voterTabDetails" :key="index">
                                                <div class="row" v-if="votergovdata.ab26 != 1">
                                                    <div class="col-lg-12 col-12 pe-4 text-end">
                                                        <label class="from-label-value me-3">Set as Primary Voter Id</label>
                                                        <InputSwitch @change="confirmModalOpen(votergovdata)" />
                                                    </div>
                                                </div>
                                                <Divider v-if="votergovdata.ab26 != 1" />
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Voter Number</label>
                                                            <div class="from-label-value text-uppercase">
                                                                {{
                                                                    votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Age</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Gender</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ak22
                                                                    ? votergovdata.ak22 == 1
                                                                        ? "Male"
                                                                        : votergovdata.ak22 == 2
                                                                            ? "Female"
                                                                            : "Others"
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Part Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Part Number</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Polling Station</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Area</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ak44
                                                                    ? votergovdata.ak44
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">State</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ak48
                                                                    ? votergovdata.ak48
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Assembly Constituency</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ab22
                                                                    ? votergovdata.ab22
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Assembly Constituency Number</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    votergovdata.ab23
                                                                    ? votergovdata.ab23
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Parliamentary Constituency</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    votergovdata.ab24
                                                                    ? votergovdata.ab24
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- open modal to display if voter ID was exits are Multiple end here -->
                                    <!-- when enter voter id display confirmation detail to update start here -->
                                    <div v-if="voterupdatedisplayflag">
                                        <div class="voter-detail-card mb-3">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Voter Number</label>
                                                        <div class="from-label-value text-uppercase">
                                                            {{
                                                                votergovconfirmdata.ak35 ? votergovconfirmdata.ak35 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ak5 ? votergovconfirmdata.ak5 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Age</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovconfirmdata.ak19 ? votergovconfirmdata.ak19 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-12 text-end" v-if="updateVoterBtnStatus">
                                                    <button type="button"
                                                        @click="confirmUpdateModalOpen(votergovconfirmdata)"
                                                        class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                                        :style="{ width: '70px' }">
                                                        <span v-if="!showupdateloader">Confirm</span>
                                                        <div class="spinner-border custom-spinner-icon-loader-btn"
                                                            role="status" v-if="showupdateloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Gender</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ak22
                                                                ? votergovconfirmdata.ak22 == 1
                                                                    ? "Male"
                                                                    : votergovconfirmdata.ak22 == 2
                                                                        ? "Female"
                                                                        : "Others"
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Name</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ab4 ? votergovconfirmdata.ab4 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Part Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovconfirmdata.ak85 ? votergovconfirmdata.ak85 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Polling Station</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ab7 ? votergovconfirmdata.ab7 : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Area</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ak44
                                                                ? votergovconfirmdata.ak44
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">State</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ak48
                                                                ? votergovconfirmdata.ak48
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ab22
                                                                ? votergovconfirmdata.ab22
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Assembly Constituency Number</label>
                                                        <div class="from-label-value">
                                                            {{
                                                                votergovconfirmdata.ab23
                                                                ? votergovconfirmdata.ab23
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <div class="customer-label-group mb-2">
                                                        <label class="form-label">Parliamentary Constituency</label>
                                                        <div class="from-label-value text-capitalize">
                                                            {{
                                                                votergovconfirmdata.ab24
                                                                ? votergovconfirmdata.ab24
                                                                : "N/A"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- when enter voter id display confirmation detail to update end here -->
                                </div>
                                <div v-if="showloadervotermodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voteraadhardetails" role="tabpanel"
                            aria-labelledby="voter-aadharcard-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderaadharmodal">
                                    <div v-if="aadhardivdisplayflag == 1">
                                        <div class="row">
                                            <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                                                <div class="customer-label-group">
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <img v-if="AadharTabDetails.ak33 == null ||
                                                                AadharTabDetails.ak33 == ''
                                                                " src="/assets/images/profile.svg"
                                                                class="rounded bg-light me-auto d-block" alt=""
                                                                width="90" />
                                                            <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + AadharTabDetails.ak33"
                                                                class="rounded bg-light me-auto d-block" alt="" width="90"
                                                                v-if="AadharTabDetails.ak33 != null &&
                                                                    AadharTabDetails.ak33 != ''
                                                                    " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ak5
                                                                    ? AadharTabDetails.ak5
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Aadhar Number</label>
                                                            <div class="from-label-value">
                                                                {{ maskedNumber(AadharTabDetails.ak36) ?
                                                                    maskedNumber(AadharTabDetails.ak36) : "N/A" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharupdatebtnflag">
                                                <button type="button"
                                                    @click="confirmAadharUpdateModalOpen(AadharTabDetails.ak36)"
                                                    class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                                    :style="{ width: '70px' }">
                                                    <span v-if="!showupdateloader">Update</span>
                                                    <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                                        v-if="showupdateloader">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                                                <div class="col-12">
                                                    <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value">
                                                        {{ format_date(AadharTabDetails.ak18) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            AadharTabDetails.ak22
                                                            ? AadharTabDetails.ak22 == 1
                                                                ? "Male"
                                                                : AadharTabDetails.ak22 == 2
                                                                    ? "Female"
                                                                    : "Others"
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">House</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak148
                                                            ? AadharTabDetails.ak148
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Landmark</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak50
                                                            ? AadharTabDetails.ak50
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Street</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak147
                                                            ? AadharTabDetails.ak147
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Location</label>
                                                    <div class="from-label-value text-capitalize">
                                                        N/A
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Post Office</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak92
                                                            ? AadharTabDetails.ak92
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Area</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak44
                                                            ? AadharTabDetails.ak44
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">City</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak46
                                                            ? AadharTabDetails.ak46
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Sub Dist</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak146
                                                            ? AadharTabDetails.ak146
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">State</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak48
                                                            ? AadharTabDetails.ak48
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Address</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            AadharTabDetails.ak42
                                                            ? AadharTabDetails.ak42
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Pincode</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            AadharTabDetails.ak97
                                                            ? AadharTabDetails.ak97
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Last Updated Date</label>
                                                    <div class="from-label-value">
                                                        {{ format_date(AadharTabDetails.ak137) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="aadhardivdisplayflag == 0">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formaadharcardinput" class="form-label">Aadhar No.
                                                                <span class="text-danger">*</span></label>
                                                            <input type="text" @input="checkAadharNumber()"
                                                                v-model="aadharcardnumber"
                                                                class="form-control text-uppercase" id="formaadharcardinput"
                                                                placeholder="Enter Aadhar No." autocomplete="off"
                                                                maxlength="12" @paste="onPasteMobile" @keypress="onlyNumber"
                                                                :disabled="displayMobilePopUp" />
                                                            <span class="custom-error" v-if="aadharerr">
                                                                {{ aadharerr }}
                                                            </span>
                                                            <span class="custom-error" v-if="sendOTPMessage">{{
                                                                sendOTPMessage }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!displayMobilePopUp">
                                                        <div class="custom-form-group" style="margin-top: 26px;">
                                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                                :style="{ width: '100px' }" @click="sendAadharOtp()"
                                                                :disabled="aadharcardnumber.length != 12 || showloader">
                                                                <span v-if="!showloader">Send OTP</span>
                                                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12" v-if="displayMobilePopUp">
                                                        <div class="custom-form-group">
                                                            <label for="formaadharcardinput" class="form-label">Enter OTP
                                                                <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="aadharotp"
                                                                class="form-control otp-input-space"
                                                                id="formaadharcardinput" placeholder="Enter OTP"
                                                                autocomplete="off" maxlength="6" minlength="6"
                                                                @keypress="onlyNumber" @paste="onPasteMobile" :disabled="mobile_otp_status == 1" />
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <span class="custom-error" v-if="mobileotperr">
                                                                    {{ mobileotperr }}
                                                                </span>
                                                                <span v-if="timerMobileStatus"
                                                                    class="otp-counter-text mt-1">{{
                                                                        timerMobileInterval
                                                                    }}</span>
                                                                <button @click="resendAadharOtp()"
                                                                    class="btn btn-link aadhar-resent-otp-btn"
                                                                    v-if="resentotpbtnstatus" :style="{ width: '80px' }"
                                                                    :disabled="showresentloader">
                                                                    <span v-if="!showresentloader">Resend OTP</span>
                                                                    <div class="spinner-border custom-spinner-icon-loader-btn"
                                                                        role="status" v-if="showresentloader">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="displayMobilePopUp">
                                                        <div class="custom-form-group" style="margin-top: 26px;">
                                                            <button type="button" class="header-btn-outer btn btn-success"
                                                                @click="verifyAadharOtp()" :style="{ width: '80px' }"
                                                                :disabled="aadharotp.length != 6 || showloader">
                                                                <span v-if="!showloader">Verify</span>
                                                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="alreadyExitMessage">
                                                    <div class="col-12">
                                                        <div class="custom-img-error">{{ this.alreadyExitMessage }}</div>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="systemlimiterrormsg">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <span class="custom-img-error">{{ systemlimiterrormsg }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderaadharmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterpandetails" role="tabpanel" aria-labelledby="voter-pancard-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderpanmodal">
                                    <div v-if="pandivdisplayflag == 1">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Pan Number</label>
                                                    <div class="from-label-value text-uppercase">
                                                        {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Category</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            PanTabDetails.ad5 == 1
                                                            ? "Person"
                                                            : PanTabDetails.ad5 == 2
                                                                ? "Business"
                                                                : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="pandivdisplayflag == 0">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formpancardinput" class="form-label">Pan No
                                                                <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="pancardnumber"
                                                                class="form-control text-uppercase" id="formpancardinput"
                                                                placeholder="Enter Pan Card No" autocomplete="off"
                                                                maxlength="10" />
                                                            <span class="custom-error" v-if="errpan">
                                                                {{ errpan }}
                                                            </span>
                                                            <span class="custom-error" v-if="errmsg">
                                                                {{ errmsg }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="
                            col-lg-4 col-md-4 col-12
                          ">
                                                        <div class="custom-form-group" style="margin-top: 26px;">
                                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                                @click="getpancarddetails()" :disabled="errStatus || pancardnumber.length == 0 || showloader
                                                                    " :style="{ width: '104px' }">
                                                                <span v-if="!showloader">Get Details</span>
                                                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderpanmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterdrivingdetails" role="tabpanel"
                            aria-labelledby="voter-driving-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderdrivingmodal">
                                    <div v-if="drivingdivdisplayflag == 1">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                                                <div class="customer-label-group">
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <img v-if="LicenseTabDetails.ac18 == null ||
                                                                LicenseTabDetails.ac18 == ''
                                                                " src="/assets/images/profile.svg"
                                                                class="rounded bg-light me-auto d-block" alt=""
                                                                width="90" />
                                                            <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/drivingphoto/' + LicenseTabDetails.ac18"
                                                                class="rounded bg-light me-auto d-block" alt="" width="90"
                                                                v-if="LicenseTabDetails.ac18 != null ||
                                                                    LicenseTabDetails.ac18 != ''
                                                                    " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">License Number</label>
                                                            <div class="from-label-value text-uppercase">
                                                                {{
                                                                    LicenseTabDetails.ac3
                                                                    ? LicenseTabDetails.ac3
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    LicenseTabDetails.ac4
                                                                    ? LicenseTabDetails.ac4
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Issue</label>
                                                            <div class="from-label-value">
                                                                {{ format_date(LicenseTabDetails.ac14) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Expiry</label>
                                                            <div class="from-label-value">
                                                                {{ format_date(LicenseTabDetails.ac13) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value">
                                                        {{ format_date(LicenseTabDetails.ac12) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac11
                                                            ? LicenseTabDetails.ac11 == 1
                                                                ? "Male"
                                                                : LicenseTabDetails.ac11 == 2
                                                                    ? "Female"
                                                                    : "Others"
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Father / Husband Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            LicenseTabDetails.ac15
                                                            ? LicenseTabDetails.ac15
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Permanent Address</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac5 ? LicenseTabDetails.ac5 : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Permanent Pincode</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac6 ? LicenseTabDetails.ac6 : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Temporary Address</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac7 ? LicenseTabDetails.ac7 : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Temporary Pincode</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac8 ? LicenseTabDetails.ac8 : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Ola Name</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac9 ? LicenseTabDetails.ac9 : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Ola Code</label>
                                                    <div class="from-label-value">
                                                        {{
                                                            LicenseTabDetails.ac10
                                                            ? LicenseTabDetails.ac10
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="drivingdivdisplayflag == 0">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formdrivinginput" class="form-label">Driving Licence
                                                                No
                                                                <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="drivingnumber"
                                                                class="form-control text-uppercase" id="formdrivinginput"
                                                                placeholder="Enter Driving Licence No" autocomplete="off"
                                                                maxlength="15" />
                                                            <span class="custom-error" v-if="errdriving">
                                                                {{ errdriving }}
                                                            </span>
                                                            <span class="custom-error" v-if="errmsg">
                                                                {{ errmsg }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formBirthDateInput" class="form-label">Birth
                                                                Date<span class="text-danger">*</span></label>
                                                            <Calendar v-model="licencebirthdate" :minDate="minDateBirth"
                                                                :maxDate="maxDateBirth" :showIcon="true"
                                                                class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                                                dateFormat="dd/mm/yy" :manualInput="false"
                                                                :monthNavigator="true"
                                                                :yearRange="'1950:' + maxDateBirth.getFullYear()"
                                                                appendTo="body" :yearNavigator="true">
                                                            </Calendar>
                                                        </div>
                                                    </div>
                                                    <div class="
                          col-lg-4 col-md-4 col-12
                        ">
                                                        <div class="custom-form-group" style="margin-top: 26px;">
                                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                                @click="getdrivingdetails()"
                                                                :disabled="errStatus || drivingnumber.length == 0 || this.licencebirthdate == null || showloader"
                                                                :style="{ width: '104px' }">
                                                                <span v-if="!showloader">Get Details</span>
                                                                <div class="
                                spinner-border
                                text-light
                                custom-spinner-loader-btn
                              " role="status" v-if="showloader">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderdrivingmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterschemesdetails" role="tabpanel"
                            aria-labelledby="voter-schemes-tab">
                            <div v-if="selected_tab == 1">
                                <VoterSchemeDetailModalTabs />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterhistorydetails" role="tabpanel"
                            aria-labelledby="voter-history-tab">
                            <div v-if="selected_tab == 2">
                                <VoterHistoryDetailsModalaTabs />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterhierachydetails" role="tabpanel"
                            aria-labelledby="voter-hierarchy-tab">
                            <div v-if="selected_tab == 5">
                                <OrganizationCharts />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterengagementsdetails" role="tabpanel"
                            aria-labelledby="voter-engagements-tab">
                            <div v-if="selected_tab == 6">
                                <EngagementTotalVoterModal />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="voterinfluencersdetails" role="tabpanel"
                            aria-labelledby="voter-influencers-tab">
                            <div v-if="selected_tab == 7">
                                <MaintenerTotalVoterModal />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- next action modal end here -->

    <!-- Edit Voter Detail start here -->
    <div class="modal-mask" v-if="editvoterdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit Voter Details</h5>
                    <button type="button" class="btn-close" @click="voterDetailEditModalClose()"></button>
                </div>
                <div class="px-4 pt-2">
                    <div class="stepper-label-edit-voter">
                        <span v-if="voterEditStepFirstStatus">Step<span class="stepper-label-circle-voter">1</span></span>
                        <span v-if="voterEditStepTwoStatus">Step<span class="stepper-label-circle-voter">2</span></span>
                        <span v-if="voterEditStepThrirdStatus">Step<span class="stepper-label-circle-voter">3</span></span>
                    </div>
                </div>
                <Divider />
                <!-- First Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepFirstStatus">
                    <div style="position: relative;
  min-height: 443px;">
                        <div v-if="!showloadervotermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="countrycode" :options="countryCodeList"
                                                    :searchable="true" label="label" placeholder="Select" disabled
                                                    class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                    :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                                    :canDeselect="false" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" v-model="edit.primobile" id="editmobileInput" disabled class="form-control"
                                                    placeholder="Enter Mobile No." maxlength="10" @paste="onPasteMobile" @keypress="onlymobileEditFormat"
                                                    autocomplete="off" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.edit.primobile.$error">
                                            {{ v$.edit.primobile.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formTitleInput" class="form-label">First Name<span
                                                class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="edit.title" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" :options="titleList" :searchable="false"
                                                    label="label" placeholder="Select"
                                                    class="multiselect-custom custom-title-multiselect text-capitalize"
                                                    :canClear="false" :closeOnSelect="true" :object="true"
                                                    noOptionsText="No Result" :canDeselect="false"
                                                    @select="handleEditGender" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" v-model="edit.firstname" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" 
                                                    class="form-control text-capitalize" id="formFirstNameInput"
                                                    placeholder="Enter First Name" autocomplete="off" maxlength="20"
                                                    @keypress="isLetterWithOutSpace($event)" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.edit.title.$error">
                                            {{ v$.edit.title.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="edit.title != '' && v$.edit.firstname.$error">
                                            {{ v$.edit.firstname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formMiddleNameInput" class="form-label">Middle Name</label>
                                        <input type="text" v-model="edit.middlename" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" class="form-control text-capitalize"
                                            id="formMiddleNameInput" placeholder="Enter Middle Name" autocomplete="off"
                                            maxlength="20" @keypress="isLetterWithOutSpace($event)" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Last Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="edit.lastname" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" class="form-control text-capitalize"
                                            id="formLastNameInput" placeholder="Enter Last Name" autocomplete="off"
                                            maxlength="20" @keypress="isLetterWithOutSpace($event)" />
                                        <div class="custom-error" v-if="v$.edit.lastname.$error">
                                            {{ v$.edit.lastname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Email Id</label>
                                        <input type="text" v-model="edit.email" :disabled="addvoterloader" class="form-control" id="formEmailInput"
                                            placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                                        <div class="custom-error" v-if="v$.edit.email.$error">
                                            {{ v$.edit.email.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Gender<span class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.gender" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" :options="edit.genderList" :searchable="false"
                                            label="label" placeholder="Select" class="multiselect-custom" :canClear="false"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.gender.$error">
                                            {{ v$.edit.gender.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formBirthDateInput" class="form-label">Birth Date<span
                                                class="text-danger">*</span></label>
                                        <Calendar v-model="edit.birthdate" :disabled="addvoterloader || (edit.aadhar != null && edit.aadhar != 0)" :minDate="minDateBirth" :maxDate="maxDateBirth"
                                            :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                            dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true"
                                            :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                                            :yearNavigator="true">
                                        </Calendar>
                                        <div class="custom-error" v-if="v$.edit.birthdate.$error">
                                            {{ v$.edit.birthdate.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Lok Sabha Name<span class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.loksabha" :disabled="addvoterloader" :options="loksabhaList" :searchable="true"
                                            @change="getvidhansabhaName($event)" @keypress="isLetterWithSpace($event)"
                                            label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                                            :canClear="true" :closeOnSelect="true" :object="true"
                                            noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.loksabha.$error">
                                            {{ v$.edit.loksabha.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Vidhan Sabha Name<span
                                                class="text-danger">*</span></label>
                                        <Multiselect v-show="!vidhanloaderflag" :disabled="addvoterloader" v-model="edit.vidhansabha"
                                            :options="vidhansabhaList" :searchable="true" label="label" placeholder="Select"
                                            @keypress="isLetterWithSpace($event)" class="multiselect-custom text-capitalize"
                                            :canClear="true" :closeOnSelect="true" :object="true"
                                            noOptionsText="No Result" />
                                        <Multiselect v-show="vidhanloaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.vidhansabha.$error">
                                            {{ v$.edit.vidhansabha.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Blood Group</label>
                                        <Multiselect v-model="edit.bloodgroup" :disabled="addvoterloader" :options="bloodgroupList" :searchable="true"
                                            label="label" placeholder="Select" class="multiselect-custom" :canClear="true"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Profession</label>
                                        <Multiselect v-model="edit.profession" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="professionList" :searchable="true"
                                            label="label" placeholder="Select" class="multiselect-custom" :canClear="true"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-show="!isCompanyNameExist">
                  <div class="custom-form-group">
                    <label class="form-label">Company / Business Name</label>
                    <Multiselect v-model="edit.companyname" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="companyList" :searchable="true" label="label"
                            placeholder="Search Company Name" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result Found" 
                      :createOption="false"  />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here to add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isCompanyNameExist">
                  <div class="custom-form-group">
                    <label for="createcom" class="form-label">Add Company / Business Name</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherCompanyName"
                      placeholder="Enter Company / Business Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="!isDesignationExist">
                  <div class="custom-form-group">
                    <label class="form-label">Designation</label>
                    <Multiselect v-model="edit.designation" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="clientdesignationList" :searchable="true"
                      label="label" placeholder="Search Designation" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                      :createOption="false" />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here to add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isDesignationExist">
                  <div class="custom-form-group">
                    <label for="createdesingation" class="form-label">Add Designation</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherDesignationName"
                      placeholder="Enter Designation Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select City<span class="text-danger">*</span></label>
                                        <Multiselect v-show="!cityloaderflag" :disabled="addvoterloader" v-model="edit.city" :options="cityList"
                                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                                            noOptionsText="No Result" :object="true" @select="getAreaByCityId(edit.city)"
                                            required />
                                        <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.city.$error">
                                            {{ v$.edit.city.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Area<span v-if="areaList != null"
                                                class="text-danger">*</span></label>
                                        <Multiselect v-show="!arealoaderflag" :disabled="addvoterloader" v-model="edit.area" :options="areaList"
                                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                                            noOptionsText="No Result" :object="true" required />
                                        <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.area.$error">
                                            {{ v$.edit.area.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Pincode<span class="text-danger">*</span></label>
                                        <input type="text" v-model="edit.pincode" :disabled="addvoterloader" id="editpincodeInput"
                                            class="form-control text-capitalize" placeholder="Enter Pincode" maxlength="6"
                                            @keypress="onlypincodeEditFormat" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.edit.pincode.$error">
                                            {{ v$.edit.pincode.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                                        <input type="text" v-model="edit.landmark" :disabled="addvoterloader" class="form-control text-capitalize"
                                            id="formLandmarkInput" placeholder="Enter Landmark" autocomplete="off"
                                            maxlength="50" />
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label for="formShortNameInput" class="form-label">Current Address<span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" :disabled="addvoterloader" v-model="edit.fulladdress"
                                            class="form-control custom-text-area-scroll" placeholder="Enter Full Address"
                                            autocomplete="off" rows="1" maxlength="250"></textarea>
                                        <div class="custom-error" v-if="v$.edit.fulladdress.$error">
                                            {{ v$.edit.fulladdress.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label d-flex justify-content-between"><span>Select
                                                Tags</span><small class="muted-text text-primary">(Max
                                                5)</small></label>
                                        <Multiselect v-model="edit.tagname" :disabled="addvoterloader" :options="taggroupList" mode="tags"
                                        :createTag="false" label="label" :class="this.allowtagremoveaccess == 0 ? 'tagdisabled' : ''" class="multiselect-custom form-custom-select text-capitalize"
                                        placeholder="Select" :closeOnSelect="true" :canClear="false" :searchable="false" :object="true" :max="5" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Photo</label>
                                        <FileUpload mode="basic" :disabled="addvoterloader" accept="image/*" :auto="true" ref="voterprofileimg"
                                            class="custom-upload-btn-ultima" v-model="edit.voterprofileimg"
                                            v-on:change="handleUploadVoterProfile()" />
                                        <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                                    <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''"
                                        :src="edit.importprofilefile" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                                    <img src="/assets/images/profile.svg" v-if="edit.importprofilefile == null" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                                    <img :src="exitpreviewurl.objectURL" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                                </div>
                                <div class="text-primary" v-if="exitpreviewurl != null">{{ exitpreviewurl.name }}</div>
                            </div>
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterEditStepFirstStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateVoterDetailbtn()"
                            :disabled="addvoterloader">
                            <span v-if="!addvoterloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addvoterloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- First Stepper End Here -->
                <!-- Second Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepTwoStatus">
                    <div style="position: relative;
min-height: 443px;">
                        <div v-if="!showloadervotermodal">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Voter Type<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" @change="checkAddVoterAuth()"
                                                v-model="edituser.votertype" name="impact-option" id="onlyvoter"
                                                autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="onlyvoter">Only
                                                Voter</label>
                                            <input type="radio" class="btn-check" @change="checkAddUserAuth()"
                                                v-model="edituser.votertype" name="impact-option" id="karyakarta"
                                                autocomplete="off" value="2" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="karyakarta">
                                                Karyakartas</label>
                                            <input type="radio" class="btn-check" @change="checkAddUserAuth()"
                                                v-model="edituser.votertype" name="impact-option" id="user"
                                                autocomplete="off" value="3" />
                                            <label class="btn text-capitalize" for="user">User</label>
                                        </div>
                                        <div class="custom-error" v-if="v$.edituser.votertype.$error">
                                            {{ v$.edituser.votertype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!exitvotererrormsg">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12"
                                            v-if="edituser.votertype == 2 || edituser.votertype == 3">
                                            <div class="custom-form-group">
                                                <label class="form-label">Designation<span
                                                        class="text-danger">*</span></label>
                                                <Multiselect v-model="edituser.userdesignation"
                                                    :options="userdesignationList" :searchable="true" label="label"
                                                    placeholder="Select" class="multiselect-custom"
                                                    :canClear="true" :closeOnSelect="true" :object="true"
                                                    noOptionsText="No Result" />
                                                <div class="custom-error" v-if="v$.edituser.userdesignation.$error">
                                                    {{ v$.edituser.userdesignation.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12"
                                            v-if="edituser.votertype == 2 || edituser.votertype == 3">
                                            <div class="custom-form-group">
                                                <label class="form-label">Team Lead<span v-if="teamleadList != null"
                                                        class="text-danger">*</span></label>
                                                <Multiselect v-model="edituser.teamleadname" :options="teamleadList"
                                                    :searchable="true" label="label" placeholder="Select"
                                                    class="multiselect-custom text-capitalize" :canClear="true"
                                                    :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                                <div class="custom-error" v-if="v$.edituser.teamleadname.$error">
                                                    {{ v$.edituser.teamleadname.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="edituser.votertype == 2 || edituser.votertype == 3">
                                        <div class="col-lg-5 col-md-5 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">Permission Type<span
                                                        class="text-danger">*</span></label>
                                                <div class="custom-group-radio-box-btn">
                                                    <input type="radio" class="btn-check" v-model="edituser.roletype"
                                                        name="role-option" id="rolewise" autocomplete="off" value="1" />
                                                    <label class="btn custom-radio-box-btn text-capitalize"
                                                        for="rolewise">Set
                                                        Wise</label>
                                                    <input type="radio" class="btn-check" v-model="edituser.roletype"
                                                        name="role-option" id="manualrole" autocomplete="off" value="2" />
                                                    <label class="btn text-capitalize" for="manualrole">Manual
                                                        Permission</label>
                                                    <div class="custom-error" v-if="v$.edituser.roletype.$error">
                                                        {{ v$.edituser.roletype.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12" v-if="edituser.roletype == 1">
                                            <div class="custom-form-group">
                                                <label class="form-label">Permission Set<span
                                                        class="text-danger">*</span></label>

                                                <Multiselect v-model="edituser.userrole" :options="userrole_list"
                                                    :searchable="false" label="label" placeholder="Select Permission Set"
                                                    class="multiselect-custom text-capitalize" :canClear="true"
                                                    :closeOnSelect="true" />
                                                <div class="custom-error"
                                                    v-if="v$.edituser.userrole.$error && roletype == 1">
                                                    {{ v$.edituser.userrole.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        v-if="edituser.roletype == 2 && (edituser.votertype == 2 || edituser.votertype == 3)">
                                        <div class="col-12">
                                            <div class="custom-form-group">
                                                <label for="formModulesInput" class="form-label">Select Modules<span
                                                        class="text-danger">*</span></label>
                                                <div class="flex flex-wrap gap-2 mb-2">
                                                    <button type="button" class="btn custom-view-detail-btn me-2"
                                                        @click="expandAll"><i class="pi pi-plus me-2"
                                                            style="font-size: 0.7rem"></i>Expand All</button>
                                                    <button type="button" class="btn custom-view-detail-btn"
                                                        @click="collapseAll"><i class="pi pi-minus me-2"
                                                            style="font-size: 0.7rem"></i>Collapse All</button>
                                                </div>
                                                <Tree :value="nodes" selectionMode="checkbox"
                                                    v-model:selectionKeys="edituser.selectedTreeValue"
                                                    :expandedKeys="expandedKeys" class="custom-users-role">
                                                </Tree>
                                                <div class="custom-error"
                                                    v-if="v$.edituser.selectedTreeValue.$error && edituser.roletype == 2">
                                                    {{ v$.edituser.selectedTreeValue.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="exitvotererrormsg">
                                <div class="col-lg-12 col-md-12 col-12" v-if="voterexitstatus == 1">
                                    <span class="custom-img-error">This Mobile Number already registered as an User.</span>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12"
                                    v-if="(voterexitid == '' || voterexitid == null) && (addharexitid == '' || addharexitid == null)">
                                    <span class="custom-img-error">Please edit Voter and add Aadhar & Voter ID details.</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterEditStepTwoStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateUserPermissionbtn()"
                        :disabled="(voterexitid == '' || voterexitid == null) && (addharexitid == '' || addharexitid == null) && edituser.votertype != 1 || editvoterloader">
                            <span v-if="!editvoterloader"><span v-if="edituser.votertype == 1">Submit</span><span
                                    v-if="edituser.votertype != 1">Next</span></span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="editvoterloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Second Stepper End Here -->
                <!-- Third Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepThrirdStatus">
                    <div style="position: relative;
min-height: 443px;">
                        <div v-if="!showloadervotermodal">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to use Mobile App : </label>
                                        <InputSwitch v-model="allow.usermobapppermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to make Outgoing Calls to voters :
                                        </label>
                                        <InputSwitch v-model="allow.displaymakeoutgoingcallvoterpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display voter's Mobile Number :
                                        </label>
                                        <InputSwitch v-model="allow.usermobpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display voter's Email ID :
                                        </label>
                                        <InputSwitch v-model="allow.useremailpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Aadhaar No. : </label>
                                        <InputSwitch v-model="allow.useraadharpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display user's Mobile Number :
                                        </label>
                                        <InputSwitch v-model="allow.displayusermobilenumberpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display user's Email ID :
                                        </label>
                                        <InputSwitch v-model="allow.displayuseremailidpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Karyakarta's Mobile Number
                                            : </label>
                                        <InputSwitch v-model="allow.displaykaryakartamobilenumberpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to display Karyakarta's Email ID :
                                        </label>
                                        <InputSwitch v-model="allow.displaykaryakartaemailidpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to add a Voter & Voter's Family :
                                        </label>
                                        <InputSwitch v-model="allow.displayaddvoterfamilypermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to edit Voter Details manually :
                                        </label>
                                        <InputSwitch v-model="allow.usereditpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to add Voter's Professional Information :
                                        </label>
                                        <InputSwitch v-model="allow.displayaddvoterprofessionalinfopermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to modify Voter's Professional Information :
                                        </label>
                                        <InputSwitch v-model="allow.displaymodifyvoterprofessionalinfopermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to manage or modify Family Relations of Voters :
                                        </label>
                                        <InputSwitch v-model="allow.displaymanagemodifyfamilyrelationpermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to remove a Member from Voter's Family Member Group :
                                        </label>
                                        <InputSwitch v-model="allow.displayremovememberfromfamilygrouppermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div class="customer-label-group divider-custom-list customer-label-group">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="from-label-value me-3">Allow to Add Tags to Voter : </label>
                                            <InputSwitch v-model="allow.displayvotertagaddpermission" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end" v-if="this.allow.displayvotertagaddpermission == true">
                                            <div class="custom-form-group mb-0 d-flex">
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="allow.displayaddtagresponsepermission" name="none-option" id="directresponse"
                                                        autocomplete="off" value="0" />
                                                    <label class="form-check-label text-capitalize" for="directresponse">Directly</label>
                                                </div>
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="allow.displayaddtagresponsepermission" name="with-option" id="withapproval"
                                                        autocomplete="off" value="1" />
                                                    <label class="form-check-label text-capitalize" for="withapproval">With Approval</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="this.edituser.votertype == 3">
                                    <div class="customer-label-group divider-custom-list customer-label-group">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="from-label-value me-3">Allow to Remove Tags from Voter : </label>
                                            <InputSwitch v-model="allow.displayvotertagremovalpermission" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end" v-if="this.allow.displayvotertagremovalpermission == true">
                                            <div class="custom-form-group mb-0 d-flex">
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="allow.displayremovaltagresponsepermission" name="none-removeoption" id="removedirectresponse"
                                                        autocomplete="off" value="0" />
                                                    <label class="form-check-label text-capitalize" for="removedirectresponse">Directly</label>
                                                </div>
                                                <div class="form-check custom-chat-radio-form form-check-inline">
                                                    <input type="radio" class="form-check-input" v-model="allow.displayremovaltagresponsepermission" name="with-removeoption" id="removewithapproval"
                                                        autocomplete="off" value="1" />
                                                    <label class="form-check-label text-capitalize" for="removewithapproval">With Approval</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow to Mark Voter / User / Karyakarta as a VIP / VVIP :
                                        </label>
                                        <InputSwitch v-model="allow.displaymarkvoteruservippermission" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group">
                                        <div class="customer-label-group">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <label class="from-label-value me-3">Allow to Delete Voter : </label>
                                                <InputSwitch @change="CheckAllowDeleteVoterAccess(allow.displaydeletevoterpermission)" v-model="allow.displaydeletevoterpermission" />
                                            </div>
                                            <small class="text-danger" v-if="this.deletevotermessage">{{this.deletevotermessage}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloadervotermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="voterEditStepThrirdStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserPermission()"
                            :disabled="editpermissionloader">
                            <span v-if="!editpermissionloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="editpermissionloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Third Stepper End Here -->
            </div>
        </div>
    </div>
    <!-- Edit Voter Detail end here -->
    <!-- confirmation Update Voter Details modal start here -->
    <div class="modal-mask" v-if="confirm_update_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmUpdateModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="updateVoterPermission()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation Update Voter Details modal end here -->
    <!-- confirmation Update Aadhar Details modal start here -->
    <div class="modal-mask" v-if="confirm_aadhar_update_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showaconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmAadharUpdateModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="updateAadharReloadPermission()"
                            :disabled="showaconfirmloaderbtn">
                            <span v-if="!showaconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showaconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation Update Aadhar Details modal end here -->
    <!-- confirmation update Voter Card as a primary modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmprivoterloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="changePrimaryVoterStatus()"
                            :disabled="showconfirmprivoterloaderbtn">
                            <span v-if="!showconfirmprivoterloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmprivoterloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation update Voter Card as a primary modal end here -->
    <!-- Bulk Campaign modal start here -->
    <div class="modal-mask" v-if="bulkcampaignmodalflag">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Bulk Campaign</h5>
                    <button type="button" class="btn-close" @click="bulkCampaignModalModalClose()"></button>
                </div>
                <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active mx-2" id="smart-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#smartcampaigndetails" type="button" role="tab"
                            aria-controls="appointment-history" aria-selected="true">
                            Smart
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link mx-2" id="sms-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#smscampaigndetails" type="button" role="tab" aria-controls="vehicle-details"
                            aria-selected="false">
                            SMS
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link mx-2" id="whatsapp-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#whatsappcampaigndetails" type="button" role="tab"
                            aria-controls="customer-details" aria-selected="false">
                            WhatsApp
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link mx-2" id="rcs-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#rcscampaigndetails" type="button" role="tab" aria-controls="contact-history"
                            aria-selected="false">
                            RCS
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link mx-2" id="calls-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#callscampaigndetails" type="button" role="tab" aria-controls="customer-details"
                            aria-selected="false">
                            Calls
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link mx-2" id="email-campaign-tab" data-bs-toggle="tab"
                            data-bs-target="#emailcampaigndetails" type="button" role="tab" aria-controls="customer-details"
                            aria-selected="false">
                            Email
                        </button>
                    </li>
                </ul>
                <div class="modal-body">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="smartcampaigndetails" role="tabpanel"
                            aria-labelledby="smart-campaign-tab">
                            <div class="modal-scroll-min-height text-center">
                                <img src="/assets/images/um.png" width="600" class="underprogress-page" />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="smscampaigndetails" role="tabpanel"
                            aria-labelledby="sms-campaign-tab">
                            <div class="modal-scroll-min-height text-center">
                                <img src="/assets/images/um.png" width="600" class="underprogress-page" />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="whatsappcampaigndetails" role="tabpanel"
                            aria-labelledby="whatsapp-campaign-tab">
                            <div class="modal-scroll-min-height">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select Voter <span
                                                    class="text-danger">*</span></label>
                                            <div class="custom-group-radio-box-btn">
                                                <input type="radio" class="btn-check" v-model="capwhats.selectbulkvoter"
                                                    name="bulk-count-option" id="selectcustombulk" autocomplete="off"
                                                    value="1" />
                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                    for="selectcustombulk">Selected Voter
                                                    Only</label>
                                                <input type="radio" class="btn-check" v-model="capwhats.selectbulkvoter"
                                                    name="bulk-count-option" id="selectallbulk" autocomplete="off"
                                                    value="2" />
                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                    for="selectallbulk">Select All
                                                    Voter</label>
                                            </div>
                                            <div class="custom-error" v-if="v$.capwhats.selectbulkvoter.$error">
                                                {{ v$.capwhats.selectbulkvoter.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select Template <span
                                                    class="text-danger">*</span></label>
                                            <Multiselect v-model="capwhats.whatsapptemplate"
                                                :options="getcampaigntemplateList" :searchable="false" label="label"
                                                placeholder="Select Template" class="multiselect-custom text-capitalize"
                                                :canClear="true" :closeOnSelect="true" />
                                            <div class="custom-error" v-if="v$.capwhats.whatsapptemplate.$error">
                                                {{ v$.capwhats.whatsapptemplate.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label for="formBirthDateInput" class="form-label">Date Variable</label>
                                            <Calendar v-model="capwhats.datevariable" :showIcon="true"
                                                class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                                dateFormat="dd/mm/yy" :minDate="todaysDate" :manualInput="false"
                                                :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()"
                                                appendTo="body" :yearNavigator="true" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Campaign Name <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" v-model="capwhats.campaignname" class="form-control"
                                                placeholder="Enter Campaign Name" maxlength="30" autocomplete="off" />
                                            <div class="custom-error" v-if="v$.capwhats.campaignname.$error">
                                                {{ v$.capwhats.campaignname.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="button" class="btn modal-bulk-next-btn"
                                            @click="assignBulkWhatsAppCampaignBtn()" :disabled="bulkwhatsapploader">
                                            <span v-if="!bulkwhatsapploader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="bulkwhatsapploader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="rcscampaigndetails" role="tabpanel"
                            aria-labelledby="rcs-campaign-tab">
                            <div class="modal-scroll-min-height text-center">
                                <img src="/assets/images/um.png" width="600" class="underprogress-page" />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="callscampaigndetails" role="tabpanel"
                            aria-labelledby="calls-campaign-tab">
                            <div class="modal-scroll-min-height">
                                <div class="row">
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select Template <span
                                                    class="text-danger">*</span></label>
                                            <Multiselect v-model="capcall.calltemplate" :options="getcampaigntemplateList"
                                                :searchable="false" label="label" placeholder="Select Template"
                                                class="multiselect-custom text-capitalize" :canClear="true"
                                                :closeOnSelect="true" />
                                            <div class="custom-error" v-if="v$.capcall.calltemplate.$error">
                                                {{ v$.capcall.calltemplate.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label for="formBirthDateInput" class="form-label">Date Variable</label>
                                            <Calendar v-model="capcall.datevariable" :showIcon="true"
                                                class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                                dateFormat="dd/mm/yy" :minDate="todaysDate" :manualInput="false"
                                                :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()"
                                                appendTo="body" :yearNavigator="true" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Campaign Name <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" v-model="capcall.campaignname" class="form-control"
                                                placeholder="Enter Campaign Name" maxlength="30" autocomplete="off" />
                                            <div class="custom-error" v-if="v$.capcall.campaignname.$error">
                                                {{ v$.capcall.campaignname.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group mb-1">
                                            <label class="form-label">Upload Mp3<span class="text-danger">*</span></label>
                                            <FileUpload mode="basic" accept=".mp3" ref="bulktuneref"
                                                class="custom-upload-btn-ultima" v-model="capcall.bulktunefile"
                                                v-on:change="handleUploadBulkCampaignCall()" />
                                            <div class="custom-error" v-if="errorbulktunefile">{{ errorbulktunefile }}</div>
                                            <div class="custom-error"
                                                v-if="v$.capcall.bulktunefile.$error && !errorbulktunefile">
                                                {{ v$.capcall.bulktunefile.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-primary mb-2" v-if="exitbulkmp3previewurl != null">{{
                                        exitbulkmp3previewurl.name }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="button" class="btn modal-bulk-next-btn"
                                            @click="assignBulkCallCampaignBtn()" :disabled="bulkcallloader">
                                            <span v-if="!bulkcallloader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="bulkcallloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="emailcampaigndetails" role="tabpanel"
                            aria-labelledby="email-campaign-tab">
                            <div class="modal-scroll-min-height text-center">
                                <img src="/assets/images/um.png" width="600" class="underprogress-page" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Bulk Campaign modal end here -->
    <!-- whatsApp History modal start here -->
    <div class="modal-mask" v-if="whatsappmodalsflag">
        <div
            class="modal-dialog modal-lg modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100">
            <div class="modal-content vh-100">
                <div class="modal-header whatsapp-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        <div class="d-flex align-items-bottom">
                            <div class="flex-shrink-0">
                                <img v-if="(this.whatsappvoterprofile == null || this.whatsappvoterprofile == '') && (this.whatsappaadharprofile == null || this.whatsappaadharprofile == '')"
                                    src="/assets/images/profile.svg" class="profile-header-avatar rounded-circle"
                                    alt="whatsappprofile" width="35" height="35" />
                                <img v-if="this.whatsappvoterprofile != null && this.whatsappvoterprofile != ''"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + this.whatsappvoterprofile"
                                    class="profile-header-avatar rounded-circle" :alt="this.whatsappvoterprofile" width="35"
                                    height="35" />
                                <img v-if="(this.whatsappaadharprofile != null && this.whatsappaadharprofile != '') && (this.whatsappvoterprofile == null || this.whatsappvoterprofile == 'NULL' || this.whatsappvoterprofile == '')"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + this.whatsappaadharprofile"
                                    class="profile-header-avatar rounded-circle" :alt="this.whatsappaadharprofile"
                                    width="35" height="35" />
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <div class="whatsapp-header-name text-capitalize">{{ this.whatsappname ? this.whatsappname :
                                    "N/A" }}</div>
                                <div class="whatsapp-header-number" v-if="this.whatsappnumber != ''">
                                    <span v-if="this.allowshowmobilevoterfk == 0">{{ maskedNumber(this.whatsappnumber) }}</span>
                                    <span v-if="this.allowshowmobilevoterfk == 1">{{ this.whatsappnumber }}</span>
                                </div>
                            </div>
                        </div>
                    </h5>
                    <div class="d-flex justify-content-end align-items-center">
                        <InputSwitch v-model="ApiLoadingSwitch" class="me-3" @change="switchLoadingStatus()" />
                        <button type="button" title="Refresh" class="btn custom-outline-refresh-btn me-3"
                            @click="getwhatsappchatlogs()">
                            <i class="pi pi-refresh refresh-btn-color"></i>
                        </button>
                        <button type="button" class="btn-close" @click="whatsappHistoryModalClose()"></button>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="chatbox-right-outer">
                        <div class="chatbox-inner-content">
                            <div class="widget-chat p-dialog-content">
                                <div id="chatcontainer" class="conversation-container" v-if="!showloadermodal">
                                    <div v-for="msg in whatsAppChatHistory" :key="msg">
                                        <div class="message received" v-if="msg.lr3 == 1">
                                            <div v-if="msg.lr20" class="mb-1">
                                                <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                                                    style="vertical-align: middle; width:300px"
                                                    class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                            </div>
                                            <div>
                                                {{ decodeHtml(msg.lr4) }}
                                            </div>
                                            <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6"
                                                    style="vertical-align: middle"
                                                    class="whatsapp-received-image p-mt-1" /></a>
                                            <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                              style="vertical-align: middle"
                                                              class="whatsapp-received-image p-mt-1" /></a>
                                                      <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                              :src="msg.ct10" style="vertical-align: middle"
                                                              class="whatsapp-received-image p-mt-1" /></a> -->
                                            <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                                            }}</span></span>
                                        </div>

                                        <div class="message sent" v-if="msg.lr3 == 2">
                                            <div v-if="msg.lr20" class="mb-1">
                                                <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                                                    style="vertical-align: middle; width:300px"
                                                    class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                            </div>
                                            <div>
                                                {{ decodeHtml(msg.lr4) }}
                                            </div>
                                            <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6"
                                                    style="vertical-align: middle" class="whatsapp-Sent-image p-mt-1" /></a>
                                            <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                              style="vertical-align: middle"
                                                              class="whatsapp-received-image p-mt-1" /></a>
                                                      <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                              :src="msg.ct10" style="vertical-align: middle"
                                                              class="whatsapp-received-image p-mt-1" /></a> -->
                                            <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                                            }}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloadermodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-0">
                    <div class="whatsapp-textbtn-outer">
                        <div class="custom-chat-picture text-success" v-if="uploadfilename">
                            File attatched
                        </div>
                        <div class="conversation-compose">
                            <textarea class="form-control input-msg custom-chat-group-textarea" :autoResize="true"
                                v-model="chatInput" rows="2" :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : ''" :disabled="this.disabledWhatsAppInputPermission == 0" placeholder="Type Message here..." />
                            <div class="send-btn-outer">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 ms-2">
                                        <label class="fileUpload btn-default upload-document" :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : 'cursor-pointer'">
                                            <i class="pi pi-paperclip" style="font-size: 1rem" v-bind:style="[this.disabledWhatsAppInputPermission == 0 ? { color: '#e9ecef' } : '']"></i>

                                            <input type="file" hidden="" name="uploadfile" ref="uploadfile" multiple
                                                v-on:change="validateAttachment" :disabled="this.disabledWhatsAppInputPermission == 0" />
                                        </label>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <button class="send" @click="sendMessage()" :disabled="this.disabledWhatsAppInputPermission == 0 || showloaderbtn">
                                            <i class="pi pi-send" v-if="!showloaderbtn" style="font-size: 1rem"></i>
                                            <div class="
                                          spinner-border
                                          text-light
                                          custom-spinner-loader-btn
                                        " role="status" v-if="showloaderbtn"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- whatsApp History modal end here -->
    <div v-if="ImagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Image Preview</h4>
                    <button class="btn-close" @click="whatsappimagepreviewClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation Delete Voter modal start here -->
  <div class="modal-mask" v-if="confirm_delete_voter_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmdeleteloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmDeleteVoterModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="confirmDeleteVoterModalProcessBtn()"
              :disabled="showconfirmdeleteloaderbtn">
              <span v-if="!showconfirmdeleteloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                v-if="showconfirmdeleteloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal end here -->
  <!-- Web Bot History modal start here -->
  <div class="modal-mask" v-if="webbotmodalsflag">
        <div
            class="modal-dialog modal-md modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100 webchat-history-bg-color">
            <div class="modal-content vh-100 conversation">
                <div class="chatbot-header-outer-padding">
                    <div class="chatbot-header-inner-bg">
                        <div class="webbot-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                                <img
                  v-if="(this.webbotvoterprofile == null || this.webbotvoterprofile == '') && (this.webbotaadharprofile == null || this.webbotaadharprofile == '')"
                  src="/assets/images/profile.svg" class="profile-header-avatar rounded-circle"
                                                alt="" width="46" height="46" />
                <img v-if="this.webbotvoterprofile != null && this.webbotvoterprofile != ''"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + this.webbotvoterprofile"
                   :alt="this.webbotvoterprofile" class="profile-header-avatar rounded-circle" width="46" height="46" />
                <img
                  v-if="(this.webbotaadharprofile != null && this.webbotaadharprofile != '') && (this.webbotvoterprofile == null || this.webbotvoterprofile == 'NULL' || this.webbotvoterprofile == '')"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + this.webbotaadharprofile"
                   :alt="this.webbotaadharprofile" class="profile-header-avatar rounded-circle" width="46" height="46" />
                                        </div>
                                        <div class="flex-grow-1" style="margin-left: 15px;">
                                            <div class="webbot-header-name text-capitalize">{{ this.webbotname ?
                                                this.webbotname :
                                                "N/A" }}</div>
                                            <div class="webbot-header-number">{{this.webbotnumber}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <InputSwitch v-model="ApiWebBotLoadingSwitch" class="me-3" @change="switchWebLoadingStatus()" />
                                        <button type="button" title="Refresh" class="btn custom-web-outline-refresh-btn me-3"
                                            @click="getWebBotchatlogs()">
                                            <i class="pi pi-refresh refresh-btn-color"></i>
                                        </button>
                                        <button type="button" class="btn-close custom-chat-btn-close" @click="webbotHistoryModalClose()"></button>
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="chatbox-web-right-outer">
                        <div class="chatbox-web-inner-content">
                            <div class="p-dialog-web-content">
                                <div id="webchatcontainer" class="webbot-conversation-container" v-if="!showwebbotloadermodal">
                                    <div v-for="(value, key ) in webbotChatHistory" :key="key" class="message-outer-body">
                                        <div class="text-center chatbot-date-header">
                                            <div class="date-outer-header">
                                                <span class="date-inner-header"><span class="date-label">{{ format_datemonth(value.date_group) }}</span></span>
                                            </div>
                                        </div>
                                        <div v-for="msg in value.grouped_data" :key="msg">
                                            <div v-if="msg.lo3 == 1">
                                                <div class="message-chat sent "  >
                                                    <div class="message-send-padding">
                                                        <div v-if="msg.lo16" class="mb-1">
                                                            <img :src="msg.lo16"
                                                                style="vertical-align: middle;"
                                                                class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                        </div>
                                                        <div v-if="msg.lo14 != 4">
                                                            <span v-html="replacebrake(msg.lo4)"></span>
                                                        </div>
                                                        <div v-if="msg.lo14 == 4">{{ msg.lo4 }}</div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img :src="msg.lo6"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                    </div>
                                                </div>
                                                <div class="metadata-send" style="min-width: 100%;"><span class="time">{{
                                                format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                            <div v-if="msg.lo3 == 2">
                                                <div class="message-chat received-chatbot">
                                                    <div class="">
                                                        <div v-if="msg.lo16" class="mb-1">
                                                            <img :src="msg.lo16"
                                                            style="vertical-align: middle; width: 100%;border-radius: 9.41px 0px 0px 0px;"
                                                                class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                        </div>
                                                        <div v-if="msg.lo14 != 4" class="message-text-received message-received-padding">
                                                            <span v-html="replacebrake(msg.lo4)"></span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div v-if="msg.lo14 == 4">
                                                            <button type="button" class="whatsappbtnreply">{{ msg.lo4 }}</button>
                                                        </div>
                                                        <div class="mt-2" v-if="msg.lo17 || msg.lo18 || msg.lo19 || msg.lo20 || msg.lo21">
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo17">{{ msg.lo17 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo18">{{ msg.lo18 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo19">{{ msg.lo19 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo20">{{ msg.lo20 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo21">{{ msg.lo21 }}</button>
                                                        </div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img :src="msg.lo6"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-Sent-image p-mt-1" /></a>
                                                    </div>
                                                </div>
                                                <div class="metadata-received" style="min-width: 100%;"><span class="time">{{
                                                format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showwebbotloadermodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Web Bot History modal end here -->
</template>
<script>
import IncomplateVoterJs from "./IncompleteVoter.js";
export default IncomplateVoterJs;
</script>
<style scoped>
.company-header-box-outer {
    padding: 9px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 0;
}

.company-legal-name {
    font-family: "AcuminPro-SemiBold";
    letter-spacing: 0px;
    color: #2b2e38;
    font-size: 16px;
    line-height: 20px;
    white-space: break-spaces;
}

.company-detail-box-outer {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}
.p-highlight .company-detail-box-outer{
  background: rgba(255, 179, 0, 0.12);
}
.branch-detail-box-outer {
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
    padding: 16px;
    border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
    border-bottom: 0;
}

.customer-label-group {
    margin-bottom: 20px;
}

.form-label-header {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #2b2e38;
    line-height: 16px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    word-break: break-word;
}

.company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
    display: block;
}

.company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.custom-outline-whatsapp-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 5px 6px;
    line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
    color: #2ca270;
    font-size: 18px;
}

.custom-outline-call-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 7.5px;
    line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
    color: #2ca270;
    font-size: 13px;
}

.custom-outline-ellipsis-btn {
    padding: 0;
    border: 0;
}

.text-star-blank {
    color: #c1c5c9;
}

.voter-report-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 12px;
    border: 1px solid #e0e5ed;
    border-radius: 4px 4px 0px 0px;
    border-top: 0;
}

.feedback-chart-outer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
}

.feedback-chart-outer .chart-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    padding: 16px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

/* whatApp style css start here */
.horizontal-line {
    border: 0.5px solid #e9e9e9;
    opacity: 1;
}

.chatbox-inner-content {
    border-bottom: 1px solid #e9e9e9;
    opacity: 1;
}

.chatbox-inner-content:hover {
    /* border: 1px solid var(--unnamed-color-e9e9e9); */
    background: #f7f9fd 0% 0% no-repeat padding-box;
    /* border: 1px solid #E9E9E9; */
    opacity: 1;
}

.chatbox-right-outer {
    max-width: 752px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 550px; */
    padding-left: 0px;
    border-radius: 0.3rem;
    height: 100%;
}

.chatbox-right-outer .chatbox-inner-content {
    /* padding: 20px 0px 0px; */
    opacity: 1;
    height: 100%;
}

.widget-chat {
    position: relative;
    min-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.p-dialog-content {
    position: relative;
    background: #efe7dd url("/assets/images/whatsapp-bg-img.jpg") repeat;
    z-index: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
}

.conversation-container {
    height: calc(100% - 5px);
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 50%;
    word-wrap: break-word;
}

.message.sent {
    background: #d2f1e1;
    border-radius: 5px 0px 5px 5px;
    float: right;
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    font-size: 13px;
    opacity: 1;
    font-family: "AcuminPro-Regular";
}

.message.received {
    background: #cfddf5;
    border-radius: 0px 5px 5px 5px;
    float: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-family: "AcuminPro-Regular";
    font-size: 13px;
}

.metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
}

.whatsapp-header {
    background: #005e54 0% 0% no-repeat padding-box;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}

.whatsapp-header .btn-close {
    background-color: #ffffff;
}

.whatsapp-header-name {
    font-size: 17px;
}

.whatsapp-header-number {
    font-size: 12px;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #7f7777 !important;
}

.metadata .time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
}

.message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #d2f1e1;
    top: 0;
    right: -10px;
}

.message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #cfddf5 transparent transparent;
    top: 0;
    left: -10px;
}

.message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
}

.whatsapp-received-image {
    max-width: 150px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: 100%;
    z-index: 2;
}

.conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
    height: 50px !important;
    overflow: auto !important;
    border-radius: 5px 0 0 5px;
}

.conversation-compose .input-msg,
.conversation-compose .attachment {
    background: #fff;
    height: 100%;
}
.conversation-compose .input-msg:disabled,
.conversation-compose .send:disabled {
    background-color: #e9ecef;
}
.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    margin-left: 8px;
    padding: 0;
    position: relative;
    outline: none;
}

.send-btn-outer .pi.pi-send {
    font-size: 1.5rem;
    transform: rotate(45deg);
}

.conversation-compose .send {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 2px;
    border: 0;
}

/* .conversation-compose .send:hover {
  border-color: #86b7fe;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-textbtn-outer {
    padding-left: 8px;
    padding-top: 10px;
    padding-right: 8px;
    background-color: #d3d3d3;
    padding-bottom: 9px;
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    margin: 0;
}

.conversation-compose .attachment {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
}

.conversation-compose .attachment:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 0;
    right: -10px;
}

.conversation-compose .attachment .p-fileupload.p-fileupload-basic {
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}

.conversation-compose .attachment .p-fileupload-basic .p-fileupload-choose {
    display: block;
    background-color: transparent;
}

.conversation-compose .attachment .pi {
    display: block;
    color: #7d8488;
    font-size: 24px;
    margin-right: 0px;
}

.conversation-compose .attachment .p-button-label {
    display: none;
}

.conversation-compose .attachment .p-ink {
    display: none;
}

.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    padding: 10px 10px;
    resize: none;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    text-align: start;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    border: 1px solid #86b7fe;
    box-shadow: none;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}

.custom-outline-refresh-btn {
    padding: 6.5px;
    line-height: 10px;
    opacity: .5;
    background-color: #ffffff;
    border-radius: 0.25rem;
}

.custom-outline-refresh-btn:hover {
    opacity: .75;
}

.custom-outline-refresh-btn .refresh-btn-color {
    color: #000000;
    font-size: 13px;
}

@media only screen and (min-width: 1024px) {
    .chatbox-right-outer {
        max-width: 100% !important;
    }
}

/* whatApp style css end here */
.status-primary {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-secondary {
  background: #ffd8b2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #c79b6f;
  line-height: 12px;
}

.status-other {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.user-badge {
  border-radius: 2px;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
}
.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}
.divider-custom-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d4e4f3;
}
.custom-chat-radio-form {
    min-height: 2.5rem;
    align-items: center;
    display: flex;
}
.custom-chat-radio-form .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 10px;
}
.custom-chat-radio-form .form-check-label {
    text-align: left;
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>